import { Avatar } from 'simple-core-ui'
import React from 'react'
import Chart from 'react-apexcharts'
import WarningIcon from './WarningIcon'
import s from '../styles/AIChat.scss'
import { buildChartOptions } from '../utils'
import { IMessage } from '../types/models'
import { zonedTimeToUtc, utcToZonedTime, format } from 'date-fns-tz'
import { HiSparkles } from 'react-icons/hi2'

const convertServerToBrowserTimezone = (serverDate: string): string => {
  const serverTimezone = 'America/Los_Angeles' // see settings.py
  const utcDate = zonedTimeToUtc(serverDate, serverTimezone)
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const browserLocalDate = utcToZonedTime(utcDate, browserTimeZone)
  const formattedBrowserLocalDate = format(browserLocalDate, "MMMM d, yyyy 'at' h:mm a", {
    timeZone: browserTimeZone
  })
  return formattedBrowserLocalDate
}

const Message: React.FC<{ message: IMessage }> = ({ message }) => {
  const user = window.credentials.user
  const { role, text, created_date, message_type, graph_definition } = message

  const renderMessageBody = (message_type: string) => {
    switch (message_type) {
      case 'final_answer_graph':
        if (!graph_definition) {
          console.error('Graph definition is missing')
          return <div>{text}</div>
        }
        const { options, series, type } = graph_definition
        const apexOptions = buildChartOptions(type, options)
        return <Chart options={apexOptions} series={series} type={type} width="600" />
      case 'loading':
        return <div className={s['blinking-dot']}></div>
      case 'error':
        return (
          <div className={s['error']}>
            <WarningIcon /> {text}
          </div>
        )
      default:
        return <div>{text}</div>
    }
  }

  return (
    <div className={[s['message'], role && s[role]].filter(Boolean).join(' ')}>
      <div className={s['message-bubble']}>
        {role === 'user' && (
          <Avatar
            className={s['avatar-list']}
            styles={{ marginLeft: 0, background: '#3C99FE', color: '#fff' }}
            initials={user.firstName + ' ' + user.lastName}
          />
        )}
        {role === 'assistant' || role === 'system' ? (
          <div className={s['avatar-list']}>
            <HiSparkles style={{ width: '25px', height: '25px', color: '#7C248E' }} />
          </div>
        ) : null}
        <div>
          <div className={s['message-date']}>
            {created_date === null
              ? format(new Date(), "MMMM d, yyyy 'at' h:mm a")
              : convertServerToBrowserTimezone(created_date)}
          </div>
          {renderMessageBody(message_type)}
        </div>
      </div>
    </div>
  )
}

export default Message
